import './App.css';
import {useState, useEffect, useRef, useCallback} from 'react';
import Axios from 'axios'
import Spell from './components/Spell.js';
import React from 'react'
import GoogleLogin from 'react-google-login'


const API_URL = "https://vrsl3f0iuj.execute-api.us-east-2.amazonaws.com/dev/";

let invSort = 1;

const nameSort = (a, b) => {
  return (invSort)*(a.spellname > b.spellname ? 1 : -1);
}

const levelSort = (a, b) => {
  return (invSort)*(a.spelllevel - b.spelllevel > 0 ? 1 : a.spelllevel < b.spelllevel ? -1 : (invSort)*nameSort(a, b));
}

let currentSort = levelSort;




function App() {

  const searchTerm = useRef([]);
  const searchString = useRef("");
  const namesakeCheckbox = useRef();

  const [spellList, setSpellList] = useState([]);
  const [collectionsList, setCollectionsList] = useState([]);
  const [userName, setUserName] = useState("");
  const [googleId, setGoogleId] = useState("0");
  const [userEmail, setUserEmail] = useState("");
  const [currentCollection, setCurrentCollection] = useState([]);
  const [googleButtonText, setGoogleButtonText] = useState("Login with Google");
  const [expressionArray, setExpressionArray] = useState([]);
  const [descOpen, setDescOpen] = useState(false);
  const [namesakeVisibility, setNamesakeVisibility] = useState(true);




  const FlipDescStatus = () => {
    setDescOpen(!descOpen);
  }

  const loadSpells = useCallback(() => {
    const storedSpells = JSON.parse(localStorage.getItem("spellData"));
    const lastUpdated = Number(localStorage.getItem("lastUpdated"));
    if (storedSpells && lastUpdated && Date.now() - lastUpdated < (14*24*360000)) {
      setSpellList(storedSpells);
    } else {
      try {
        Axios.get(API_URL+"read").then((response) => {
          localStorage.setItem("spellData", JSON.stringify(response.data));
          localStorage.setItem("lastUpdated", Date.now());
          setSpellList(response.data);
        });
      } catch (err) {
        console.error(err);
      }
    }
    
  }, []);

  const loadCollections = () => {
    try {
      Axios.get(`${API_URL}getcollections/${googleId}`).then((response) => {
        setCollectionsList(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  const responseGoogleSuccess = (response) => {
    //console.log(response);
    try {
      Axios.post(API_URL+"googlelogin", response).then((resp) => {
        setUserName(resp.data.name);
        setGoogleId(resp.data.googleId);
        setUserEmail(resp.data.email);
      });
      setGoogleButtonText(userEmail);
    } catch (err) {
      console.error(err);
    }
  }

  const createCollection = () => {
    if (userName === "") {
      alert ("You must be logged in to create a collection.");
      return;
    }
    const c_name = prompt("Name your new Collection.");
    Axios.post(API_URL+"createcollection", {name: c_name, owner_id: googleId}).then((res) => {
        setCollectionsList([...collectionsList, res.data])
    });
  }

  const responseGoogleFailure = (response) => {
    console.log(response);
  }

  
  
  useEffect( () => {
     loadSpells();
      
  }, [loadSpells]);

  const filterByCollection = () => {
    loadSpells();
   }

   const makeExpressionArray = (exp) => {
      exp = exp.trim();
      if (exp === "") return "" 
      if (exp.charAt(0) === '(') exp = exp.substring(1, exp.length);
      let expArray = [];
      let depth = 0;
      let entered = false;
      let startIndex = 0;

      [...exp].forEach((e, index) => {
        if (e === '(') {
          depth++;
          entered = true;
        } else if (e === ')') {
          depth--;
        }
        if (!entered) {
          if (e === '|') {
            expArray.push(exp.substring(startIndex, index).trim());
            expArray.push("|");
            startIndex = index+1;
          } else if (e === '&') {
            expArray.push(exp.substring(startIndex, index).trim());
            expArray.push("&");
            startIndex = index+1;
          } else if (e === '^') {
            expArray.push(exp.substring(startIndex, index).trim());
            expArray.push("^");
            startIndex = index+1;
          }
        } else {
          if (depth === 0) {
            expArray.push(makeExpressionArray(exp.substring(startIndex, index)));
            startIndex = index+1;
            entered = false;
          }
        }
      })
      expArray.push(exp.substring(startIndex, exp.length).trim());
      expArray = expArray.filter((e) => e !== ' ' && e !== '');
      return expArray;
   }

   const evaluateExpressionArray = (arrOriginal, item) => {
      let arr = [...arrOriginal];
      if(arr.length === 0) return true;
      if (arr.length === 1) {
        const rv = evaluateFilter(arr[0], item);
        return rv;
      } 
      else if (arr.length > 3) {
        const exp = arr.slice(0,3);
        const rem = arr.slice(3);
        rem.unshift(evaluateExpressionArray(exp, item));
        return evaluateExpressionArray(rem, item);
      }
      let currentResult = true;
      if (Array.isArray(arr[0])) {
        const rsplice = evaluateExpressionArray(arr[0], item);
        arr.splice(0,0,rsplice);
      }
      if(Array.isArray(arr[2])) {
        const rsplice = evaluateExpressionArray(arr[2], item);
        arr.splice(2,0,rsplice);
      }
      switch(arr[1]) {
        case '|':
          currentResult = evaluateFilter(arr[0], item) | evaluateFilter(arr[2], item);
          break;
        case '&':
          currentResult = evaluateFilter(arr[0], item) & evaluateFilter(arr[2], item);
          break;
        case '^':
          currentResult = evaluateFilter(arr[0], item) ^ evaluateFilter(arr[2], item);
          break;
        default:
          break;
      }
      return currentResult;
   }

   const updateReferenceArray = async() => {
     const expArray = makeExpressionArray("id &" + searchString.current.value ? searchString.current.value.toLowerCase() : ' id');
      setExpressionArray(expArray);
   }

   const evaluateFilter = (ostr, item) => {
      if (typeof ostr === 'string' || ostr instanceof String) {
        let str = ostr.slice();
        str = str.replace(')','');
        let rval = false;
        let notmod = false;
        if(str.charAt(0) === '!') {
          str = str.substring(1);
          notmod = true;
        }
        if(str.includes(":")) {
          let farray = str.split(":");
          let sJSON = JSON.parse(item);
          switch(farray[0].toLowerCase()) {
            case "name":
            case "spellname":
            case "n":
              rval = (sJSON.spellnamesake ? sJSON.spellnamesake + "'s " + sJSON.spellname : sJSON.spellname).toLowerCase().includes(farray[1]);
              break;
            case "name*":
            case "n*":
              rval = sJSON.spellname.toLowerCase().includes(farray[1]);
              break;
            case "namesake":
            case "ns":
              rval = (sJSON.spellnamesake ? sJSON.spellnamesake.toLowerCase() : "").includes(farray[1]);
              break;
            case "level":
            case "l":
            case "spelllevel":
            case "lvl":
              if(farray[1] === "cantrip") farray[1] = "0"
              if(farray[1] === "epic") farray[1] = "10"
              farray[1] = farray[1].replace(/\D/g,'');
              rval = (sJSON.spelllevel + '').includes(farray[1]);
              break;
            case "spellschool":
            case "school":
            case "s":
              rval = sJSON.spellschool.includes(farray[1]);
              break;
            case "spellritual":
            case "ritual":
            case "rit":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellritual;
              else rval = !sJSON.spellritual;
              break;
            case "spelltags":
            case "tag":
            case "tags":
            case "spelltag":
              rval = sJSON.spelltags.includes(farray[1]);
              break;
            case "castingtime":
            case "cast":
            case "casttime":
            case "ctime":
              switch(farray[1]) {
                case "action":
                case "bonus action":
                case "reaction":
                  rval = sJSON.castingtime.includes("1 "+farray[1]);
                  break;
                default:
                  rval = sJSON.castingtime.includes(farray[1]);
                  break;
              }
              break;
            case "spellrange":
            case "range":
            case "r":
              rval = sJSON.spellrange.includes(farray[1]);
              break;
            case "componentverbal":
            case "verbal":
            case "cv":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellcomponents.componentverbal;
              else rval = !sJSON.spellcomponents.componentverbal;
              break;
            case "componentsomatic":
            case "somatic":
            case "cs":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellcomponents.componentsomatic;
              else rval = !sJSON.spellcomponents.componentsomatic;
              break;
            case "componentmaterial":
            case "material":
            case "cm":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellcomponents.componentmaterial;
              else rval = !sJSON.spellcomponents.componentmaterial;
              break;
            case "componentcosted":
            case "costed":
            case "cost":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellcomponents.componentcosted;
              else rval = !sJSON.spellcomponents.componentcosted;
              break;
            case "componentmaterialtext":
            case "materials":
            case "mats":
              rval = sJSON.spellcomponents.componentmaterialtext.includes(farray[1]);
              break;
            case "durationtime":
            case "duration":
            case "d":
              rval = sJSON.spellduration.durationtime.includes(farray[1]);
              break;
            case "concentration":
            case "c":
            case "conc":
              if(farray[1] !== "false" && farray[1] !== "f") rval = sJSON.spellduration.concentration;
              else rval = !sJSON.spellduration.concentration;
              break;
            case "spelldetails":
            case "spelltext":
            case "details":
            case "text":
            case "body":
            case "t":
              rval = sJSON.spelldetails.join().includes(farray[1]);
              break;
            case "spellupcast":
            case "upcast":
            case "up":
              let noup = false;
              if (sJSON.spellupcast === null || sJSON.spellupcast === "") noup = true;
              if(farray[1] !== "false" && farray[1] !== "f") rval = !noup;
              else rval = noup;
              break;
            case "$up":
            case "$upcast":
            case "$spellupcast":
              if (sJSON.spellupcast === null || sJSON.spellupcast === "") return false;
              else return sJSON.spellupcast.includes(farray[1]);
            case "classes":
            case "class":
            case "z":
              rval = sJSON.classes.includes(farray[1]);
              break;
            case "class*":
            case "z*":
              rval = (sJSON.classes.length === 1 && sJSON.classes.includes(farray[1]));
              break;
            default:
              break;
          }
        }
         else {
          rval = item.includes(str);
        }
        return (notmod) ^ rval;
      }
      return ostr;
   }

  const currentFilter = (item) => {

    const jstr = JSON.stringify(item).toLowerCase();
    const rv = evaluateExpressionArray(expressionArray, jstr);
    return rv;
  }

  
  const collectionFilter = (item) => {
    //console.log(currentCollection);
      try { 
        if (currentCollection === null || currentCollection.owner_id ==="0") 
        { return true; }
      if (searchTerm.current['fbc'].checked === true) {
        return currentCollection.spell_ids.includes(item._id)
      }
      return true;
    } catch (err) {
      return true;
    }
  }

  const toggleNamesakes = (item) => {
    if (namesakeCheckbox) {
      setNamesakeVisibility(namesakeCheckbox.current.checked)
    }
  }

  const updateCollection = () => {
    setCurrentCollection(collectionsList.find(e => e._id === searchTerm.current['collection'].value))
  }

  const packageCollectionCommands = async() => {
    updateCollection();
    loadCollections();
    
  }

  const checkIfEnter = (event) => {
    if (event.key === "Enter") updateReferenceArray();
  }


  const deleteCurrentCollection = () => {
    if (currentCollection.owner_id === googleId && googleId !== 0) {
      const res = prompt(`Type "delete ${currentCollection.name}" to delete this collection (once you delete this, it cannot be recovered)`);
      if (res === `delete ${currentCollection.name}`) {
        Axios.delete(API_URL+"removecollection/"+currentCollection._id).then((res) => {
          console.log(res);
        });
      } else {
        alert(`Sorry, you did not properly type "delete ${currentCollection.name}"`)
      }
    } else {
      alert("Sorry, you do not have access to delete this collection.");
    }
  }

  return (
    <div className="App">
      <div className="headerBar">
        <h3>Collin Krueger Spells</h3>
        <a href="https://collinkrueger.com/alvanzia">Back to Home</a>
        <div className="login-details">
        <GoogleLogin
            clientId="862221183515-ngsnrls3b04egpehc8pqvppunhncptkg.apps.googleusercontent.com"
            buttonText={googleButtonText}
            onSuccess={responseGoogleSuccess}
            onFailure={responseGoogleFailure}
            cookiePolicy={'single_host_origin'}
        />
        </div>
        
      </div>
      

      <div className="collectionsDiv">
        <div className="collectionsDivLabels">
          <h3>Collections</h3>
        </div>
        <div className="collectionsDivR1">
          <label for="collection">
            <select name="collections" ref={el => searchTerm.current['collection']=el} onMouseEnter={loadCollections} onChange={updateCollection} onMouseLeave={updateCollection}>
                {collectionsList.sort().map((val) => {
                  return (
                    <option value={val._id}>{val.name}</option>
                  );
                })}
            </select>
          </label>
                
          <button onClick={createCollection}>New Collection</button> 
          <button onClick={deleteCurrentCollection}>Delete</button> 
        </div>
        <div className="filterByCollection">
        <label>
            Filter by Collection: 
            <input type="checkbox" ref={el => searchTerm.current['fbc']=el} onChange={filterByCollection}></input>
          </label>
          
        </div>
        <div className="filterByCollection">
        <label>
            Namesakes
            <input type="checkbox" ref={namesakeCheckbox} onChange={toggleNamesakes} defaultChecked={true}></input>
          </label>
        </div>
      </div>
      <div className="filters">
        <label>
          <input type="text" className="searchBar" placeholder="how do you want to do this...?" ref={searchString} onKeyPress={checkIfEnter}></input>
        </label>
      </div>
      <div className="filterDesc">
        <button onClick={FlipDescStatus}>...</button>
        <div className={`desc ${descOpen ? "open" : "hidden"}`}>
          <div className="descContent">
            <ul>
            <li>
                <span className="descTerm">&amp;</span>
                <span className="spacer"></span>
                <span className="descEX"> AND operator </span>
              </li>
              <li>
                <span className="descTerm">|</span>
                <span className="spacer"></span>
                <span className="descEX"> OR operator </span>
              </li>
              <li>
                <span className="descTerm">^</span>
                <span className="spacer"></span>
                <span className="descEX"> XOR operator </span>
              </li>
              <li>
                <span className="descTerm">!</span>
                <span className="spacer"></span>
                <span className="descEX"> NOT operator </span>
              </li>
              <li>
              <span className="descTerm">spellname:</span>
              <span className="descTerm">name:</span>
              <span className="descTerm">n:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by spell name (name*, n* excludes namesake) </span>
              </li>
              <li>
              <span className="descTerm">namesake:</span>
              <span className="descTerm">ns:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by spell namesake (ex. Mortimer, Jet, etc...) </span>
              </li>
              <li>
              <span className="descTerm">spelllevel:</span>
              <span className="descTerm">level:</span>
              <span className="descTerm">lvl:</span>
              <span className="descTerm">l:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by level </span>
              </li>
              <li>
              <span className="descTerm">spellschool:</span>
              <span className="descTerm">school:</span>
              <span className="descTerm">s:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by spell school </span>
              </li>
              <li>
              <span className="descTerm">spellritual:</span>
              <span className="descTerm">ritual:</span>
              <span className="descTerm">rit:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches rituals ("ritual: false" for non-rituals) </span>
              </li>
              <li>
              <span className="descTerm">spelltags:</span>
              <span className="descTerm">tags:</span>
              <span className="descTerm">tag:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by spell's tag </span>
              </li>
              <li>
              <span className="descTerm">castingtime:</span>
              <span className="descTerm">casttime:</span>
              <span className="descTerm">ctime:</span>
              <span className="descTerm">cast:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by casting time </span>
              </li>
              <li>
              <span className="descTerm">spellrange:</span>
              <span className="descTerm">range:</span>
              <span className="descTerm">r:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches by spell range </span>
              </li>
              <li>
              <span className="descTerm">componentverbal:</span>
              <span className="descTerm">verbal:</span>
              <span className="descTerm">cv:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches if component verbal (true/false) </span>
              </li>
              <li>
              <span className="descTerm">componentsomatic:</span>
              <span className="descTerm">somatic:</span>
              <span className="descTerm">cs:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches if component somatic (true/false) </span>
              </li>
              <li>
              <span className="descTerm">componentmaterial:</span>
              <span className="descTerm">material:</span>
              <span className="descTerm">cm:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches if component material (true/false) </span>
              </li>
              <li>
              <span className="descTerm">componentcosted:</span>
              <span className="descTerm">costed:</span>
              <span className="descTerm">cost:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches if component costed (true/false) </span>
              </li>
              <li>
              <span className="descTerm">componentmaterialtext:</span>
              <span className="descTerm">materials:</span>
              <span className="descTerm">mats:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches spell materials </span>
              </li>
              <li>
              <span className="descTerm">durationtime:</span>
              <span className="descTerm">duration:</span>
              <span className="descTerm">d:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches spell duration </span>
              </li>
              <li>
              <span className="descTerm">concentration:</span>
              <span className="descTerm">conc:</span>
              <span className="descTerm">c:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches if concentration (true/false) </span>
              </li>
              <li>
              <span className="descTerm">spelltext:</span>
              <span className="descTerm">text:</span>
              <span className="descTerm">t:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches spell body</span>
              </li>
              <li>
              <span className="descTerm">classes:</span>
              <span className="descTerm">class:</span>
              <span className="descTerm">z:</span>
                <span className="spacer"></span>
                <span className="descEX"> searches classes (class*, z*: exclusive spells)</span>
              </li>

                <li>
                  <span className="descTerm">Example</span>
                </li>
                <li>
                <span className="descTerm">name:fire &amp; class:artificer</span>
                </li>
                <li>
                  <span className="descEX">searches all artificer spells with the word fire in the name</span>
                </li>
                <li>
                <span className="descTerm">z:artificer &amp; (z:wizard ^ z:sorcerer)</span>
                </li>
                <li>
                  <span className="descEX">searches all artificer spells which are either wizard or sorcerer spells, but not both</span>
                </li>
              
              
            </ul>
            
          </div>
            
        </div>
      </div>
      <div className="spellList">
        <table>
          <tr>
            <th></th>
            <th>Level</th>
            <th colSpan="5">Name</th>
            <th colSpan="2">Casting Time</th>
            <th colSpan="3">Duration</th>
            <th colSpan="2">Range</th>
          </tr>
          {console.log(spellList)}
          {
          spellList.filter(collectionFilter).filter(currentFilter).sort(currentSort).map((val) => {
          return (
            <Spell data={val} curCollectionRef={currentCollection} updateCollections={packageCollectionCommands} viewNamesakes={namesakeVisibility} loggedIn={userName + 't'}></Spell>
          );
        })}
        </table>
      </div>
    </div>
  );
}

export default App;
