import React from 'react'
import {useState} from 'react'
import Axios from 'axios'
import Tooltip from 'react-power-tooltip'
import EyeOpen from '../assets/eye_open.png'
import EyeClosed from '../assets/eye_closed.png'
import BookmarkBook from '../assets/hollow_bookmark.png'
import InCollectionBook from '../assets/bookmark.png'
import HollowStar from '../assets/hollow_star.png'
import FilledStar from '../assets/filled_star.png'


const API_URL = "https://ck-spells.herokuapp.com/";

let componentString = (data) => {
    let str = "";
    if(data.spellcomponents.componentVerbal) str+="V";
    if(data.spellcomponents.componentSomatic) str==="" ? str +="S" : str += ", S";
    if(data.spellcomponents.componentMaterial) str==="" ? str +="M" : str += ", M";
    if(data.spellcomponents.componentCosted) str += "*";
    return str;
}

let componentStringFull = (data) => {
    let str = "";
    if(data.spellcomponents.componentVerbal) str+="V";
    if(data.spellcomponents.componentSomatic) str==="" ? str +="S" : str += ", S";
    let mat = "M (" + data.spellcomponents.componentMaterialText + ")";
    if(data.spellcomponents.componentMaterial) str==="" ? str += mat : str += (", " + mat);
    return str;

}

let returnOrdinalString = (level) => {
    let str = "invalid";
    switch(level) {
        case 0:
            str = "cantrip"
            break;
        case 1:
            str = "1st"
            break;
        case 2:
            str = "2nd"
            break;
        case 3:
            str = "3rd"
            break;
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            str = level+"th";
            break;
        case 10:
            str = "epic";
            break;
        default:
            str = '???';
            break;
    }
    return str;
}





export default function Spell({data, refreshCallback, curCollectionRef, updateCollections, viewNamesakes}) {
    const [isOpen, setOpen] = useState("false");
    const [isHighlighted, setHighlighted] = useState(false);
    const [isMinimized, setMinimized] = useState(false);
    const [isOptionsTooltipVisible, setOptionsTooltipVisibility] = useState(false);

    const ToggleClass = (ev) => {
        if (!ev.target.matches('.schoolIcon, .spellbook-token, .dotText, .TooltipIcon img') && !isMinimized)
            setOpen(!isOpen);
    }

    const CollectionModify = () => {
        if (curCollectionRef === undefined || curCollectionRef.length === 0 || curCollectionRef.owner_id === "0") return;
        if (isInCollection()) {
            //try to remove from spellbook
            Axios.put(API_URL+"removespellfromcollection", {collection_id: curCollectionRef._id,spell_id: data._id}).then((response) => {
                console.log("we removed it!");
                updateCollections();
            })
        } else {
            //try to add to spellbook
            Axios.put(API_URL+"addtocollection", {collection_id: curCollectionRef._id,spell_id: data._id}).then((response) => {
                console.log("we did it!");
                updateCollections();
            });
        }
    }

    const isInCollection = () => {
        //console.log(curCollectionRef);
        if (curCollectionRef === undefined || curCollectionRef.length === 0 || curCollectionRef.owner_id === "0") return false;
        try {
            return curCollectionRef.spell_ids.includes(data._id);
        } catch (err) {
            console.error(err);
            return true;
        }
    }

    const HighlightSpell = (ev) => {
        setHighlighted(!isHighlighted);  
    }

    const MinimizeSpell = (ev) => {
        setMinimized(!isMinimized);
        setOpen(true);
        setHighlighted(false);
    }
    

    return (
        <>
        <tr className={`spellHeader ${isOpen ? "closedFriend" : "openedFriend"} ${data.spelltags.includes('restricted') ? 'restricted' : ''} ${data.spelltags.includes('artifact') ? 'artifact' : ''} ${data.spelltags.includes('uncommon') ? 'uncommon' : data.spelltags.includes('rare') ? 'rare' : 'common'}
            ${isMinimized ? '' : 'spellHeaderM'}`} onClick={ToggleClass} onDoubleClick={() => isMinimized ? setMinimized(false): null}>
            {   
                isMinimized ? <td></td> :
                <td className={`td-spell-icon ${data.spelltags.includes('custom') ? 'custom' : data.spelltags.includes('5e') ? 'base' : 'modified'}`}><div className="p-2 row spell-icon"><span className={`schoolIcon ${data.spellschool.toLowerCase()}-icon `} onClick={HighlightSpell} ></span></div></td>
            }
            {
                isMinimized ? <td><small>{data.spelllevel}</small></td> :
                <td><div className="p-2 row spell-level"><span>{returnOrdinalString(data.spelllevel)}</span></div></td>
            }
            {
                isMinimized ? <td colSpan={5} className="MinimizedName">
                    <span className={`${data.spelltags.includes('weavebreaking') ? 'weavebreaking' : null} ${data.spelltags.includes('verendibel') ? 'verendibel' : null}`}>
                            <span>{(data.spellnamesake && viewNamesakes) ? data.spellnamesake + "'s " : ""}</span>{data.spellname} </span>
                </td> :
                <td className="td-spell-name" colSpan="5"><div className="p-2 row spell-name">
                    <div className="name-span">
                        <span className={`${data.spelltags.includes('weavebreaking') ? 'weavebreaking' : null} ${data.spelltags.includes('verendibel') ? 'verendibel' : null}`}>
                            <span className={`namesake`}>{(data.spellnamesake && viewNamesakes) ? data.spellnamesake + "'s " : ""}</span>{data.spellname} </span>
                        <span className={data.spellduration.concentration ? "concentration-icon" : null}></span>
                        <span className={data.spellritual ? "ritual-icon" : null}></span>
                    </div>
                    <div className="name-data"> 
                        <span>{data.spellschool}</span>
                        <span> • </span> 
                        <span>{componentString(data)}</span>
                        <span className="in-collection">{isInCollection() ? "  (in " + curCollectionRef.name + ")" : ""}</span>
                    </div>
                </div></td>
            }
            {
                isMinimized ? <td colSpan={2}></td> :
                <td colSpan="2"><div className="p-2 row casting-time" >{data.castingtime}</div></td>
            }
            {
                isMinimized ? <td colSpan={3}></td> :
                <td colSpan="3"><div className="p-2 row spell-duration">{data.spellduration.durationtime}</div></td>
            }
            {
                isMinimized ? <td colSpan={2}></td> :
                <td colSpan="2"><div className="p-2 row spell-range">{data.spellrange}</div></td>
            }
            
            
            <td colSpan="2" className={`spellOptions ${isHighlighted ? 'highlightTab' : 'nohighlightTab'}`}>
                <div className={`optionsDots`}>
                    <div className="tooltipContainer"
                        onMouseOver={() => setOptionsTooltipVisibility(true)}
                        onMouseLeave={() => setOptionsTooltipVisibility(false)}
                        
                    >
                        {
                                isHighlighted ?
                                <span className="TooltipIcon" onClick={HighlightSpell}><img src={FilledStar}/></span> :
                                <span className="TooltipIcon" onClick={HighlightSpell}><img src={HollowStar}/></span>
                            }
                        {   
                                (curCollectionRef === undefined || curCollectionRef.length === 0 || curCollectionRef.owner_id === "0") ? <></> :
                                (isInCollection()) ?
                                <span onClick={CollectionModify} className="TooltipIcon"><img src={InCollectionBook}/></span> :
                                <span onClick={CollectionModify}
                                className="TooltipIcon"><img src={BookmarkBook}/></span>
                            }
                            
                            {
                                isMinimized ?
                                <span className="TooltipIcon" onClick={MinimizeSpell}><img src={EyeClosed}/></span> :
                                <span className="TooltipIcon" onClick={MinimizeSpell}><img src={EyeOpen}/></span>
                            }

                    </div>
                </div>
                {
                    isMinimized ? <></> :
                    <div className={`
                    ${isHighlighted ? "highlighted" : "nothighlighted"}
                    preparedTab
                `}></div>
                }
                
            </td>
        </tr>
        <tr className={`spellInfo ${isOpen ? 'closed' : 'open'} ${data.spelltags.includes('restricted') ? 'restricted' : ''} ${data.spelltags.includes('artifact') ? 'artifact' : ''} ${data.spelltags.includes('uncommon') ? 'uncommon' : data.spelltags.includes('rare') ? 'rare' : 'common'}`}>
            <td colSpan="15">
                <div className="spellInfoDiv">
                    <div className="spellDetailsTop">
                        <ul>
                            <li><p className="strong">Level</p><p>{returnOrdinalString(data.spelllevel)}</p></li>
                            <li><p className="strong">Casting Time</p><p>{data.castingtime}<em>{data.spellritual ? ' (ritual)' : ''}</em></p></li>
                            <li><p className="strong">Range</p><p>{data.spellrange}</p></li>
                            <li><p className="strong">Components</p><p>{componentStringFull(data)}</p></li>
                            <li><p className="strong">Duration</p><p>{data.spellduration.concentration ? "Concentration, up to " : ""}{data.spellduration.durationtime}</p></li>
                            <li><p className="strong">Spell School</p><p>{data.spellschool}</p></li>
                        </ul>
                    </div>
                    <div className="dividerLine"></div>
                    <div className="spellMeat">
                        {data.spelldetails.map((test) => {
                            return <p key={test}>{test}</p>
                        })}
                        <p><em>{data.spellupcast}</em></p>
                    </div>
                    <div className="taglist">
                        <span>Tags: </span>
                        {data.spelltags.map((tag) => {
                            return <span key={tag} className="tag-type">{tag}</span>
                        })}
                        <span>  Classes: </span>
                        {data.classes.map((tag) => {
                            return <span key={tag} className="tag-type">{tag}</span>
                        })}                      
                    </div>
                 </div>
            </td>
        </tr>
        <tr className="break"></tr>
        
        </>
    );
}